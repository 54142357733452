import React, { useContext, useState, useEffect } from "react";
import { Breadcrumb } from "flowbite-react";

import { ChatContext } from "../../../../../Context/ChatContext";
import { useParams } from "react-router-dom";
import { HiHome } from "react-icons/hi";
import Loader from "../../../../../component/Loader";
import { view_product_by_id } from "../../../../../api/userRegestration/Campaign";
import { BiPhotoAlbum } from "react-icons/bi";
function PostProductDetails() {
  const [loading, setloading] = useState(true);
  const { receiverId, setReceiverId } = useContext(ChatContext);

  const { postId, type } = useParams();
  const [productdetails, setproductdetails] = useState({});
  const [error, setError] = useState(null);
  const types = new URLSearchParams(window.location.search).get("type");

  useEffect(() => {
    console.log(types,"babababababababb")
    view_product_by_id(postId)
      .then((result) => {
        console.log("API Response:", result);
        console.log(postId)
        setproductdetails(result);
      })
      .catch((error) => {
        console.error("Error:", error);
        setError(error);
      });
  }, [postId]);

  return (
    <>
      <div className="bg-layout shadow-md drop-shadow-md lg:w-[155vh] mb-[7rem] lg:ml-[268px] relative top-[1rem] xs:h-full">
        {productdetails && (
          <>
            <div className="--action breadcrumb--navigate-path ml-8 mt-8">
              <Breadcrumb aria-label="Default breadcrumb example">
                <Breadcrumb.Item icon={HiHome}>
                  <p className="cursor-pointer ">Home</p>
                </Breadcrumb.Item>

                <Breadcrumb.Item className="cursor-pointer">
                  Product
                </Breadcrumb.Item>

                <Breadcrumb.Item>
                {productdetails.type}
                {/* Type */}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <section className="/">
              <div className="lg:container mx-auto px-4">
                <div className="lg:col-gap-12 xs:flex-col xl:col-gap-16 mt-8 flex flex-row justify-evenly gap-7 lg:mt-12 lg:grid-cols-3 ">
                  <div className="lg:col-span-3 lg:row-end-1">
                    <div className="lg:flex lg:items-start">
                      <div className="lg:order-2 lg:ml-5">
                        <div className="max-w-xl overflow-hidden rounded-lg ">
                          <img
                            className=" h-[20rem] w-full p-2 max-w-full object-cover"
                            src={productdetails.mediaUrl}
                            alt="hshshs"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="lg:col-span-2 lg:row-span-2 lg:ml-0 lg:row-end-2">
                    <h1 className="sm: text-2xl font-bold text-gray-900 sm:text-3xl  xs:text-4xl font-all">
                      {productdetails.postTitle}
                      {/* type */}
                       <span> </span>
                    </h1>
                    <div className="mt-5 flex items-center">
                      <div className="flex items-center">
                        <svg
                          className="block h-4 w-4 align-middle text-yellow-500"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                            className=""
                          />
                        </svg>
                        <svg
                          className="block h-4 w-4 align-middle text-yellow-500"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                            className=""
                          />
                        </svg>
                        <svg
                          className="block h-4 w-4 align-middle text-yellow-500"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                            className=""
                          />
                        </svg>
                        <svg
                          className="block h-4 w-4 align-middle text-yellow-500"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                            className=""
                          />
                        </svg>
                        <svg
                          className="block h-4 w-4 align-middle text-yellow-500"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                            className=""
                          />
                        </svg>
                      </div>
                      <p className="ml-2 text-sm font-medium text-gray-500">
                        1,209 Reviews
                      </p>
                    </div>
                    <h2 className="mt-8 text-base text-gray-900 xs:text-2xl">
                      Product Details :
                      {" "}
                      {productdetails.details}
                      {" "}
                    </h2>

                    {/*  */}
                    {/* Product rate  */}
                    {/* <h2 className="mt-8 text-base text-gray-900 font-bold font-all xs:text-2xl">
                      Product Status :{" "}
                      <span className="italic font-normal">
                        {" "}
                        {productdetails.status}
                        {" "}
                      </span>
                    </h2> */}

                    {/* <h2 className="mt-3 text-base text-gray-900 font-bold font-all xs:text-2xl">
                      Amount :{" "}
                      <span className="italic font-normal">
                        {" "}
                        " {productdetails.amount} "
                      </span>
                    </h2> */}
                    {/* {types === "active_campaign" && (
                      <button className="mb-6 border-[1px] border-[#CB2229] text-black rounded-full font-all p-[6px] lg:mt-[0.5rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out relative  ">
                        Edit
                      </button>
                    )} */}

                    {/* <UserChatButton receiverId={receiverId} /> */}
                  </div>
                </div>
              </div>
            </section>
          </>
        )}
        {!productdetails && loading && <Loader />}{" "}
        {!productdetails && !loading && <p>No product details found.</p>}
      </div>
    </>
  );
}

export default PostProductDetails;
