import React, { useEffect, useState } from "react";
import { MdAddPhotoAlternate } from "react-icons/md";
import { createPost } from "../../../api/userRegestration/CollectionForm";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PromotionFormBody from "../../PromotionForm/PromotionFormBody";
import { TbSpeakerphone } from "react-icons/tb";
import Spinner from "../../../component/Spinner";
import PromotionPopup from "../../../Widgets/PromotionPopup";
import NotificationModel from "../../../Widgets/NotificationModel";
export default function CreatePost() {
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageBase64, setImageBase64] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [promotebutton, setPromoteButton] = useState(true);
  const [getserviceId, setgetserviceId] = useState();
  const [notificationmodal, setnotificationmodal] = useState(!true);
  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);
  const [err, seterr] = useState(false);

  const handleButtonClick = () => {
    toast.success("you are now at promotion form!", {
      position: "top-right",
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    setPromoteButton(!promotebutton);
    setShowModal(false);
  };

  const [formData, setFormData] = useState({
    postTitle: "",
    description: "",
  });

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 2 * 1024 * 1024) {
        seterr("Image size must be less than 2MB");
        return; // Prevent further processing if image size exceeds 2MB
      }

      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
      convertImageToBase64(file);
      seterr(false); // Convert and store the image as Base64
    } else {
      setSelectedImage(null);
      setImageBase64(null);
      seterr(false); // Clear the Base64 data if no image is selected
    }
  };
  //
  const convertImageToBase64 = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const base64Data = e.target.result;
      setImageBase64(base64Data);
    };
    reader.readAsDataURL(file);
  };
  //
  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === "checkbox" ? checked : value;
    setFormData({ ...formData, [name]: newValue });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedImage) {
      setSubmitButtonClicked(true);
    }
    setLoading(true);
    //
    try {
      const postData = {
        postTitle: formData.postTitle,
        mediaUrl: imageBase64,
        details: formData.description,
        amount: "1",
        mediaType: "MEDIA",
      };
      const Post = await createPost(postData);

      console.log("post:", Post);
      console.log(" ID FROM THE PARENT COMPONENT ", Post.result);

      console.log(
        "request  FROM THE PARENT COMPONENT ",
        Post.result.saveRequest.postId
      );

      let serviceId;

      setgetserviceId(Post.result.saveRequest.postId);
      console.log("id capsuled under state ");

      if (Post && Post.result && Post.result.saveRequest.postId) {
        serviceId = Post.result.saveRequest.postId;

        setgetserviceId((serviceId) => {
          console.log("previous job Id ", serviceId);
          return serviceId;
        });

        console.log(
          "\x1b[32m%s\x1b[0m",
          "product id from scattered state",
          serviceId
        );
      } else {
        console.log(" ID not found in the response.");
      }
      console.log("postId", Post);

      // toaster
      toast.success("  hitted  successfully", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      setLoading(false);
      setShowModal(true);

      //

      console.log("post:", Post);
    } catch (error) {
      console.error("Error in post:", error.message);
    }
  };

  useEffect(
    (take) => {
      if (getserviceId) {
        console.log("Product ID from state:", getserviceId);
      }
    },
    [getserviceId]
  );

  return (
    <div className="bg-layout drop-shadow-md lg:w-[152vh] lg:h-max lg:ml-[17rem] relative top-[1rem] ">
      {promotebutton ? (
        <>
          <div className="fade-in-left flex justify-center xs:h-screen m-auto mb-2">
            <form onSubmit={handleSubmit}>
              <div className="form">
                <h1 className="text-center font-all font-extrabold text-[23px] text-color lg:relative mt-2 mb-3">
                  Create Post
                </h1>
                <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[32rem]">
                  <label
                    htmlFor="postTitle"
                    className="text text-sm text-black w-max rounded-md bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                  >
                    Post Title
                  </label>
                  <input
                    type="text"
                    placeholder="Write here..."
                    name="postTitle"
                    value={formData.postTitle}
                    onChange={handleInputChange}
                    className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
                    required
                  />
                </div>
                {/*  */}

                <div className="flex items-center justify-center w-full my-4">
                  <label
                    style={{
                      display: selectedImage ? "none" : "block",
                    }}
                    htmlFor="dropzone-file"
                    className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 shark:hover:bg-bray-800 shark:bg-gray-700 hover:bg-gray-100 shark:border-gray-600 shark:hover:border-gray-500 shark:hover:bg-gray-600"
                  >
                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                      <svg
                        className="w-8 h-8 mb-4 text-gray-500 shark:text-gray-400"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 16"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                        />
                      </svg>
                      <p className="mb-2 text-sm text-gray-500 shark:text-gray-400">
                        <span className="font-semibold">Click to upload</span>{" "}
                        or drag and drop
                      </p>
                      <p className="text-xs text-gray-500 shark:text-gray-400">
                        SVG, PNG, JPG, or GIF (MAX 2MB)
                      </p>
                    </div>
                    <input
                      id="dropzone-file"
                      type="file"
                      className="hidden"
                      onChange={handleImageChange}
                      required
                    />
                  </label>
                  {selectedImage && (
                    <div className="flex items-center  w-auto p-[10px] h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 shark:hover:bg-bray-800 shark:bg-gray-700 hover:bg-gray-100 shark:border-gray-600 shark:hover:border-gray-500 shark:hover:bg-gray-600">
                      <img
                        className="w-full h-44 mx-auto "
                        src={selectedImage}
                        alt="Selected"
                      />
                    </div>
                  )}
                </div>

                {/*  */}
                <div className="cooltextarea flex flex-col w-fit-content static max-w-240 lg:w-[32rem] mb-4">
                  <label
                    htmlFor="description"
                    className="text text-sm text-black w-max rounded-md bg-white relative top-2.5 ml-2 px-1 w-fit-content"
                  >
                    Description
                  </label>
                  <textarea
                    placeholder="Write here..."
                    name="description"
                    rows="12"
                    value={formData.description}
                    onChange={handleInputChange}
                    className="textarea px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
                    required
                  />
                </div>
                {/* {!selectedImage && ( */}
                {/* <p className="text-red-500 text-sm mt-2">Please select an image to continue.</p> */}
                {/* )} */}
                {err && <p className="text-red-500">{err}</p>}
                {submitButtonClicked && (
                  <p className="text-red-500 text-sm mt-2">
                    Please select an image to continue.
                  </p>
                )}
                <button
                  // type="submit"
                  onClick={() => {
                    if (!selectedImage) {
                      setSubmitButtonClicked(true);
                      // Display error message

                      return; // Prevent form submission
                    }
                    // If image is selected, proceed with form submission
                    // handleSubmit();
                  }}
                  className="xs:mb-[10%]  bg-[#CB2229] flex flex-row-reverse justify-center items-center gap-2 mx-auto text-white rounded-full font-all p-[6px] lg:mt-[0.5rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out relative"
                  disabled={loading}
                >
                  {loading && <Spinner />} Post
                </button>
              </div>
            </form>
          </div>
        </>
      ) : (
        <>
          <>
            <div className="conditional action b">
              <PromotionFormBody data={getserviceId} />
            </div>
          </>
        </>
      )}

      {showModal ? (
        <>
          <PromotionPopup
            setShowModal={setShowModal}
            handleButtonClick={handleButtonClick}
            setnotificationmodal={setnotificationmodal}
          />
        </>
      ) : null}

      {notificationmodal ? (
        <>
          <NotificationModel setnotificationmodal={setnotificationmodal} />
        </>
      ) : null}

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}
