import React, { useEffect, useState } from "react";
import axios from "axios";
import { GoVerified } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { useWallet } from "../../../../Wallet/Context/WalletContext";
function CommunityPost() {
  const { postlist } = useWallet();
  const navigate = useNavigate();
  //

  useEffect(() => {
    const currentDate = new Date();
    console.log(`post rendered at: ${currentDate}`);
  }, []);

  const handle_click = (postId) => {
    console.log(postId);
    navigate(`/findproducts/postView_/${postId}`);
  };

  //
  return (
    <>
      <div className="flex items-center mt-10 p-2 rounded ">
        <div className="flex flex-col gap-4 mb-8 w-[100%]">
          <h1 className="font-all font-bold  text-start text-[21px] w-[30rem] lg:mt-[27px] mb-6">
            All Community Posts
          </h1>
          <section id="Projects" className="w-full mx-auto mt-10 mb-5">
            {postlist.map((item, index) => (
              <div
                className="flex flex-col mb-5"
                onClick={() => handle_click(item._id, "featured_product")}
                key={item._id}
              >
                <div
                  key={index}
                  className="relative rounded-xl flex flex-row shadow-lg p-3 border border-white bg-white"
                  style={{ maxWidth: "100%" }} // Set max width to 100% for responsiveness
                >
                  <div className="w-full md:w-1/3 bg-white grid place-items-center">
                    <img
                      src={item.mediaUrl}
                      alt="tailwind logo"
                      className="rounded-xl h-[13rem] w-full"
                    />
                  </div>
                  <div className="w-full md:w-2/3 bg-white flex flex-col space-y-2 ml-9">
                    
                    <h3
                      className="font-black text-gray-800 md:text-3xl font-all"
                      style={{ fontSize: "21px" }}
                    >
                      {item.postTitle}
                    </h3>
                    <div className="bg-gray-200 px-3 py-1 rounded-full w-fit text-xs font-medium text-gray-800 hidden md:block">
                        verified
                      </div>
                    <p className="md:text-lg text-gray-500 text-base">
                      {item.details.split(" ").slice(0, 6).join(" ")}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </section>

          <h1 className=" mt-2 cursor-pointer underline-offset-4 text-center font-all    hover:text-blue-600 w-[12%] mx-auto">
            See more
          </h1>
        </div>
      </div>
    </>
  );
}

export default CommunityPost;
