import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


import { GrLocation } from "react-icons/gr";
import PromotionBillingAddress from "./PromotionBillingAddress";

import {
  product_promotion,
  service_promotion,
  job_promotion,
  post_promotion,
  dedcut_money_from_wallet,
} from "../../api/userRegestration/PromotionResponse";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Spinner } from "flowbite-react";
import { useLocation } from "react-router-dom";
import { useWallet } from "../Wallet/Context/WalletContext";
// import { useWallet } from "../Wallet/Context/WalletContext";
export default function PromotionFormBody({ data }) {
  //
  const [formData, setFormData] = useState({
    keywords: [],
    suggestedKeywords: "",
    location: "",
    budget: "",
    bidAmount: "",
    maxBiddingAmount: "",
    setDate: null,
    duration: 0,
  });
  const [suberror, setsubError] = useState("");
  const handleDeleteKeyword = (indexToRemove) => {
    setKeywordChips((prevKeywords) => {
      return prevKeywords.filter((_, index) => index !== indexToRemove);
    });
  };

  

 
  const handleImageChange = (files) => {
    const imageFile = files[0]; 

    // Set the selected image preview URL
    setSelectedImagePreview(URL.createObjectURL(imageFile));

    // Store the selected image file in state
    setFormData((prevData) => ({
      ...prevData,
      photo: imageFile,
    }));
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent form submission
      const trimmedKeyword = keywordInput.trim();
      if (trimmedKeyword && keywordChips.length < 5) {
        setError("");
        setKeywordChips((prevKeywords) => [...prevKeywords, trimmedKeyword]);
        setFormData((prevData) => ({
          ...prevData,
          keyword: [...prevData.keywords, trimmedKeyword],
        }));
        setKeywordInput("");
      } else if (trimmedKeyword && keywordChips.length >= 5) {
        setError("You can only enter up to 5 keywords.");
      }
    }
  };
  const { pathname } = useLocation();
  const { walletData } = useWallet();
  const [keywordInput, setKeywordInput] = useState("");
  const [keywordChips, setKeywordChips] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedImagePreview, setSelectedImagePreview] = useState(null);
  const [headline, setHeadline] = useState("");
  const [description, setDescription] = useState("");
  const [keywords, setKeywords] = useState("");
  const [suggestedKeywords, setSuggestedKeywords] = useState("");
  const [location, setLocation] = useState("");
  const [sliderValue, setSliderValue] = useState(90);
  const [spinner, setspinner] = useState(false);
  const [error, setError] = useState("");


  

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "keyword") {
      setKeywordInput(value);
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
    
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSliderChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      sliderValue: parseInt(e.target.value),
    }));
  };
  const handleDateChange = (date) => {
    setSelectedDate(date);
    // Calculate duration based on selected date
    if (date) {
      const startDate = new Date();
      const endDate = new Date(date);
      const durationInMilliseconds = endDate - startDate;
      const durationInDays = Math.ceil(durationInMilliseconds / (1000 * 60 * 60 * 24));
      setFormData((prevData) => ({
        ...prevData,
        duration: durationInDays,
      }));
    }
  };
  //
  const handleSubmit = async (e) => {
    e.preventDefault();


     // Ensure keywords are sent as an array of strings
     const keywordsArray = Array.isArray(formData.keywords)
     ? formData.keywords.map(keyword => keyword.trim())
     : [formData.keywords.trim()];

     if (walletData.balance < formData.budget) {
      setsubError("Please add balance to your wallet as it is less than the budget you entered.");
      return;
    }
    if (walletData.bidAmount > formData.maxBiddingAmount) {
      setsubError("Bidding Amount Should be Less than Max Bidding Amount");
      return;
    }
    setsubError("");
    

   
      setspinner(true);
   

    try {
      let executePromotion;

      if (pathname.includes("/addProject/creatpost")) {
      
        executePromotion = await post_promotion({
          postId: data,
          duration: formData.duration,
          bidAmount: formData.maxBiddingAmount,
          keyword: keywordsArray,
          budget: formData.budget,
        });
      } else if (pathname.includes("findproduct")) {
        executePromotion = await product_promotion({
          productId: data,
          duration: formData.duration,
          bidAmount: formData.maxBiddingAmount,
          keyword: keywordsArray,
          budget: formData.budget,
        });
      } else if (pathname.includes("ListProject") || pathname.includes("exploreService")) {
       
        executePromotion = await service_promotion({
          serviceId: data,
          duration: formData.duration,
          bidAmount: formData.maxBiddingAmount,
          keyword: keywordsArray,
          budget: formData.budget,
        });
      } else if (pathname.includes("findjobs") || pathname.includes("PostJob")) {
     
        executePromotion = await job_promotion({
          jobId: data,
          duration: formData.duration,
          bidAmount: formData.maxBiddingAmount,
          keyword: keywordsArray,
          budget: formData.budget,
        });
      } else {
        executePromotion = await post_promotion({
          postId: data,
          duration: formData.duration,
          bidAmount: formData.maxBiddingAmount,
          keyword: keywordsArray,
          budget: formData.budget,
        });
      }

      console.log(executePromotion, "status of promoted module");

      toast.success("Promoted successfully", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      console.error(error);
      setError("Failed to promote. Please try again.");
    } finally {
      setspinner(false);
    }
  };
  
  
  

  //
  return (
    <>
      <div className=" w-[93%] mx-auto p-[5px]">
        <h1 className="text-center my-4 font-bold">promotion</h1>

        <form onSubmit={handleSubmit}>
          <div className="item-b  mt-4 mb-2 xs:w-full">
            <div className="border border-[#cfcaca82]   rounded-md w-[68%] xs:w-[95%] mx-auto shadow-sm mb-3 ">
              <h1 className="font-all font-semibold text-[19px] text-start ml-6 mt-3 mb-2">
                Select Keywords
              </h1>
              {/* - a  */}
              <div className="coolinput flex mx-auto flex-col w-fit-content static max-w-240 lg:w-[32rem] mb-3 ">
              <label
                htmlFor="input"
                className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
              >
                Add Keywords
              </label>
              <input
                type="text"
                placeholder="Add Keywords"
                name="keyword"
                onKeyPress={handleKeyPress}
                value={keywordInput}
                onChange={handleInputChange}
                className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-none"
              />
            </div>
            {error && (
              <p className="text-red-500 flex justify-center">{error}</p>
            )}
            <div className="flex justify-center mx-16 mt-2 flex-wrap">
              {keywordChips.map((keyword, index) => (
                <div
                  key={index}
                  className="bg-[#cb22289b] flex rounded-full mx-1 px-3 py-1 my-3 text-sm font-normal text-white"
                  style={{ letterSpacing: "1px" }}
                >
                  {keyword}{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-3 h-3 my-auto ml-1 cursor-pointer"
                    onClick={() => {
                      handleDeleteKeyword(index);
                      setKeywordInput("");
                    }}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18 18 6M6 6l12 12"
                    />
                  </svg>
                </div>
              ))}
            </div>
              {/* - b  */}
              <div className="coolinput flex mx-auto flex-col w-fit-content static xs:w-[95%] max-w-240 lg:w-[32rem] mb-3 ">
                <label
                  htmlFor="suggestedKeywords"
                  className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                >
                  Suggested Keywords
                </label>
                <input
                  type="text"
                  placeholder="Write here..."
                  name="suggestedKeywords"
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] xs:w-[98%] rounded-md focus:border-[1px]"
                  value={formData.suggestedKeywords}
                  required
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
          <div className="item-c mt-4 mb-2">
            <div className="border border-[#cfcaca82]   rounded-md w-[68%] xs:w-[95%] xs:p-2 mx-auto shadow-sm mb-3 ">
              <h1 className="font-all font-semibold text-[19px] text-start ml-6 mt-3 mb-2">
                Advertise Near Location
              </h1>
              {/*  */}
              <div className="--get-location drop-c flex flex-col justify-center rounded-full mx-auto p-[9px] w-[14rem]">
                <span className="flex flex-row items-center justify-center gap-2">
                  <GrLocation /> Use Current Location
                </span>
              </div>
              <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[32rem] mx-auto ">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                >
                  Location
                </label>
                <input
                  type="text"
                  placeholder="Write here..."
                  name="location"
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
                  value={formData.location}
                  required
                  onChange={handleInputChange}
                />
              </div>
              <div className="w-3/4 mx-auto mt-4 mb-4">
                <label
                  htmlFor="range"
                  className=" font-all  text-lg font-semibold"
                >
                  Selected Distance ( {sliderValue} km):
                </label>
                <input
                  type="range"
                  id="range"
                  name="range"
                  min="20"
                  max="500"
                  required
                  value={sliderValue}
                  onChange={handleSliderChange}
                  className="w-full mt-2 bg-yellow-200"
                />
                <div className="flex justify-between mt-2">
                  <span>20 km</span>
                  <span>{sliderValue} km</span>
                </div>
              </div>

              {/*  */}
            </div>
            <div className="item-d setbudget border border-[#cfcaca82]   rounded-md w-[68%] xs:w-[98%]  mx-auto shadow-sm mb-3 mt-4">
              <h1 className=" font-all font-semibold text-[19px] text-start ml-6 mt-3 mb-2">
                Add Budget
              </h1>
              {/* a  */}
              <div className="flex flex-col justify-center items-center">
                <div className="coolinput flex flex-col w-fit-content xs:w-[98%] xs:p-2 static max-w-240 lg:w-[32rem] ">
                  <label
                    htmlFor="input"
                    className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                  >
                    Enter your Budget
                  </label>
                  <input
                    type="number"
                    placeholder="Write here..."
                    name="budget"
                    // required
                    className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
                    value={formData.budget}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="coolinput flex flex-col w-fit-content static xs:w-[98%] xs:p-2 max-w-240 lg:w-[32rem] ">
                  <label
                    htmlFor="input"
                    className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                  >
                    Max Bidding amount
                  </label>
                  <input
                    type="number"
                    placeholder="Write here..."
                    name="maxBiddingAmount"
                    // required
                    className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
                    value={formData.maxBiddingAmount}
                    onChange={handleInputChange}
                  />
                </div>
                {/* b  */}
                <div className="flex flex-row  xs:gap-2 items-center justify-start gap-[10rem] ">
                <h5 className="font-base text-[19px]">
                  <h1>Current Balance</h1>
                  {walletData.balance.toLocaleString("en-IN")}
                  <span className="font-all text-sm"> inr</span>{" "}
                </h5>
                <button className=" bg-[#cac5c585] shadow-md lg:mx-auto xs:m-2 p-[6px] xs:w-[8rem] w-[13rem] rounded-full transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white">
                  add money
                </button>
              </div>
              </div>
            </div>
          </div>
          <div className="--item-d --run add-money xs:w-full">
          <div className="border border-[#cfcaca82]   rounded-md xs:w-full w-[68%] mx-auto shadow-sm mb-3 mt-4">
            <h1 className="font-all font-semibold text-[19px] text-start ml-6 mt-3 mb-2">
              How long will u take to run this ad
            </h1>
            <div className="flex flex-col items-center justify-center gap-[9px] xs:w-[90%]  ">
              <div className="flex flex-row items-center gap-2 xs:w-[90%]">
                

                <div className="coolinput flex flex-col w-fit-content static xs:w-[99%] max-w-240 lg:w-[32rem]  ">
                  <DatePicker
                    selected={selectedDate}
                    onChange={handleDateChange}
                    minDate={new Date()}
                    placeholderText="Select a date"
                    className="input px-2 py-3 text-sm border-[1px] xs:w-full border-[#738482] rounded-md focus:border-none"
                  />
                </div>
              </div>

              <hr className="w-full" />
            </div>
          </div>
        </div>

          {/* billing address form */}
          <PromotionBillingAddress />

          {/* biling addressform end here  */}

          <button
            type="submit"
            className=" bg-[#CB2229] text-white rounded-full font-all p-[6px] lg:mt-[1rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out mx-auto flex justify-center"
          >
            {spinner ? (
              <>
                <Spinner />{" "}
              </>
            ) : (
              <>Confirm and Make Payment</>
            )}
          </button>
          {suberror && (
          <p className="text-red-500 flex justify-center">{suberror}</p>
        )}
        </form>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}
