import React, { useEffect, useState, useRef } from "react";
import {
  registerUserdata,
  getAllInterests,
  getAllProductCategories,
  getallidentification,
} from "../../../../api/userRegestration/CollectionForm";
import { useWallet } from "../../../Wallet/Context/WalletContext";
import { MdModeEditOutline } from "react-icons/md";
import { IoMdCreate } from "react-icons/io";
import Spinner from "../../../../component/Spinner";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function useOutsideClick(ref, callback) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callback]);
}

function EditProfile({ togglemode, profileDetails, view }) {
  const { setProfiledata } = useWallet();
  const [interests, setInterests] = useState([]);
  const [identifications, setIdentifications] = useState([]);
  const [idetificationdropdown, setidetificationdropdown] = useState(false);
  const [categories, setCategories] = useState([]);
  const accessToken = localStorage.getItem("token");
  const [selectedCategory, setSelectedCategory] = useState("");
  // const [idetificationdropdown, setidetificationdropdown] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const interestDropdownRef = useRef(null);
  const identificationDropdownRef = useRef(null);

  useOutsideClick(interestDropdownRef, () => {
    setDropdownVisible(false);
  });

  useOutsideClick(identificationDropdownRef, () => {
    setidetificationdropdown(false);
  });
  const { profiledata } = useWallet();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [DropdownVisible, setDropdownVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageBase64, setImageBase64] = useState(null);
  const [isFileInputVisible, setIsFileInputVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [interestvalue, setinterestvalue] = useState();
  const [error, setError] = useState(null);
  const [selectedCoverImage, setSelectedCoverImage] = useState(null);
  const [coverImageBase64, setCoverImageBase64] = useState(null);
  const [selectedidentification, setSelectedidentication] = useState("");
  const [err, seterr] = useState(false);
  
  const [interesterror,setinteresterror] = useState(false);
  
  const [selectedInterests, setSelectedInterests] = useState([]);
  const [formData, setFormData] = useState({
    fullname: profiledata.name,
    email: "",
    aboutYourself: profiledata.bio,
    location: profiledata.location,
    useCurrentLocation: "",
    interest: [""],
    identification: "",
    imageFile: null,
    coverImageFile: null,
  });
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    fetchData();
    fetchIdentification();
    getAllProductCategories(accessToken)
      .then((result) => {
        setCategories(result);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [accessToken]);
  const fetchIdentification = async () => {
    console.log("jkfhdjfkhdjfdjhfdjhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh");
    try {
      const response = await getallidentification();
      setIdentifications(response.data);
      console.log("identifications", response.data);
    } catch (error) {
      console.error("Error fetching identifications:", error);
    }
  };
  const removeInterest = (interestToRemove) => {
    const updatedInterests = selectedInterests.filter(
      (interest) => interest !== interestToRemove
    );
    setSelectedInterests(updatedInterests);
  };
  const handleInterestSelect = (interest) => {
    if (selectedInterests.length < 5) {
      // Check if the interest is already selected
      if (!selectedInterests.includes(interest)) {
        // Add the selected interest to the selectedInterests array
        setSelectedInterests([...selectedInterests, interest]);
      }
      // Clear the interest input field after selection
      setFormData((prevData) => ({
        ...prevData,
        interest: "",
      }));
    } else {
      // Show error message if more than 5 interests are selected
      setinteresterror("You can only select up to 5 interests");
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);

      const userData = {
        name: formData.fullname,
        userName: formData.fullname,
        email: formData.fullname,
        gender: "MAlE",
        bio: formData.aboutYourself,
        identification: selectedCategory,
        dob: "Nothing",
        interest: selectedInterests,
        facebook: "https://www.fac00&creative=bookmark",
        twitter: "https://twitter.com/",
        instagram: "https://instagram.com/",
        linkedIn:
          "https://www.linkedin.com/signup/cold-join?session_redirect=https%3A%2F%2Fwww%2Elinkedin%2Ecom%2Ffeed%2F&trk=login_reg_redirect",
        location: formData.location,
        profilePic: imageBase64 || profiledata.profilePic,
        coverPic: coverImageBase64 || profiledata.coverPic,
      };

      const registeredUser = await registerUserdata(userData);
      console.log("Registered User:", registeredUser.result);
      setProfiledata(registeredUser.result);
      console.log("state updated siccsfully ");
      setIsLoading(false);

      toast.success("  hitted  successfully", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setTimeout(() => {
        togglemode();
      }, 1000);
      window.location.reload();
    } catch (error) {
      console.error("Error:", error);
      setError("An error occurred while updating the profile.");
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "interest") {
      // Check if the interest is already selected
      if (!formData.interests.includes(value)) {
        // Add the selected interest to the interests array
        setFormData((prevData) => ({
          ...prevData,
          interests: [...prevData.interests, value],
        }));
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const fetchData = async () => {
    try {
      const response = await getAllInterests();
      setInterests(response.data);
      console.log("interest", interests);
      console.log(JSON.stringify(response));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 2 * 1024 * 1024) {
        seterr("Image size must be less than 2MB");
        return; // Prevent further processing if image size exceeds 2MB
      }

      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
      convertImageToBase64(file);
      seterr(false);
    } else {
      setSelectedImage(null);
      setImageBase64(null);
      seterr(false);
    }
  };
  const handleCoverImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedCoverImage(imageUrl);
      convertCoverImageToBase64(file);
    } else {
      setSelectedCoverImage(null);
      setCoverImageBase64(null);
    }
  };

  const convertCoverImageToBase64 = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const base64Data = e.target.result;
      setCoverImageBase64(base64Data);
    };
    reader.readAsDataURL(file);
  };

  const handleClickOnProfileImage = () => {
    document.getElementById("profileImageInput").click();
  };

  const convertImageToBase64 = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const base64Data = e.target.result;
      setImageBase64(base64Data);
    };
    reader.readAsDataURL(file);
  };
  const toggleOption = (option) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    } else {
      // Check if the number of selected options is less than 2
      if (selectedOptions.length < 4) {
        setSelectedOptions([...selectedOptions, option]);
      }
      // Close the dropdown after selecting two options
      setDropdownVisible(false);
    }
  };

  const toggleDropdowns = () => {
    setDropdownVisible(!DropdownVisible);
  };

  return (
    <>
      <div className="bg-layout shadow-md drop-shadow-md lg:w-[155vh] mb-[7rem] lg:ml-[268px] relative top-[1rem] h-max">
        <h1 className="fade-in-left font-all text-center font-semibold lg:text-[22px] mb-2">
          Edit Update Profile
        </h1>
        <section className="bg-white shark:bg-gray-900">
          <div className="max-w-2xl px-4 py-4 mx-auto   ">
            <form action="#">
              <div className="--banner-bg  relative">
                <>
                  <img
                    className="rounded-md h-[10.1rem] w-[100%]"
                    src={coverImageBase64 || profiledata.coverPic}
                    alt=""
                  />
                  <label
                    htmlFor="coverImageInput"
                    className="cursor-pointer absolute top-0 right-0 p-2"
                  >
                    <IoMdCreate className="text-white bg-[#cb2229] rounded-full shadow-md" />
                  </label>
                  <input
                    id="coverImageInput"
                    type="file"
                    accept="image/*"
                    onChange={handleCoverImageChange}
                    style={{ display: "none" }}
                  />
                </>
              </div>

              <div className="flex flex-row">
                <div className="--user-img">
                  <label
                    htmlFor="profileImageInput"
                    className="cursor-pointer relative"
                  >
                    <img
                      className="h-[6rem] w-[6rem] rounded-full object-cover hover:opacity-5 cursor-pointer"
                      src={selectedImage || profiledata.profilePic}
                      alt="Avatar"
                      onClick={handleClickOnProfileImage}
                    />
                    <div className="absolute inset-0 rounded-full shadow-inner"></div>
                    <div className="absolute top-[73px] right-0 p-2">
                      <MdModeEditOutline className="text-white bg-[#cb2229] rounded-full shadow-md" />
                    </div>
                  </label>
                  <input
                    id="profileImageInput"
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    style={{ display: "none" }}
                  />
                </div>
              </div>
              {err && <p className="text-red-500">{err}</p>}
              <div className="grid gap-4 mb-4 sm:grid-cols-2 sm:gap-6 sm:mb-5">
                <div className="sm:col-span-2">
                  <label
                    htmlFor="name"
                    className="block mb-2 text-sm font-semibold font-all text-gray-900 shark:text-white"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    name="fullname"
                    id="name"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 shark:bg-gray-700 shark:border-gray-600 shark:placeholder-gray-400 shark:text-white shark:focus:ring-primary-500 shark:focus:border-primary-500"
                    value={formData.fullname}
                    onChange={handleInputChange}
                    required=""
                  />
                </div>
                <div className="w-full">
                  <label
                    htmlFor="brand"
                    className="block mb-2 text-sm font-medium text-gray-900 shark:text-white"
                  >
                    Bio
                  </label>
                  <input
                    type="text"
                    id="brand"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 shark:bg-gray-700 shark:border-gray-600 shark:placeholder-gray-400 shark:text-white shark:focus:ring-primary-500 shark:focus:border-primary-500"
                    name="aboutYourself"
                    value={formData.aboutYourself}
                    onChange={handleInputChange}
                    required=""
                  />
                </div>
                <div className="w-full">
                  <label
                    htmlFor="price"
                    className="block mb-2 text-sm font-medium text-gray-900 shark:text-white"
                  >
                    Location
                  </label>
                  <input
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 shark:bg-gray-700 shark:border-gray-600 shark:placeholder-gray-400 shark:text-white shark:focus:ring-primary-500 shark:focus:border-primary-500"
                    name="location"
                    value={formData.location}
                    onChange={handleInputChange}
                    required=""
                  />
                </div>
                <div
                  className="coolinput flex flex-col w-fit-content static"
                  ref={identificationDropdownRef}
                >
                  <label
                    htmlFor="select"
                    className="text text-sm text-black w-max rounded-md bg-[white] relative top-2.5 ml-2 px-1 bg-w-fit-content text-center"
                  >
                    Identification
                  </label>
                  <input
                    type="text"
                    placeholder="Write here..."
                    name="category"
                    className="input px-2 py-3 text-sm border-[1px] border-gray-300 rounded-md focus:outline-none bg-white p-[10px]"
                    value={selectedCategory} // Use the selectedCategory state here
                    onChange={(e) => {
                      handleInputChange(e);

                      setSelectedCategory(e.target.value); // Update selected identification
                    }}
                    onFocus={() => {
                      setidetificationdropdown(!idetificationdropdown);
                    }}
                    required=""
                    autoComplete="off"
                  />
                  {idetificationdropdown && (
                    <ul className="absolute z-10  w-[19.5rem] mt-16 h-40 overflow-scroll  bg-white border border-gray-300 rounded-md shadow-lg">
                      {identifications.map((interest) => (
                        <li
                          key={interest._id}
                          className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                          onClick={() => {
                            setSelectedCategory(interest.name);
                            setidetificationdropdown(false);
                          }}
                        >
                          {interest.name}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
                <div
                  className="coolinput flex flex-col w-fit-content static"
                  onClick={toggleDropdown}
                  ref={interestDropdownRef}
                >
                  <label
                    htmlFor="select"
                    className="text text-sm text-black w-max rounded-md bg-[white] relative top-2.5 ml-2 px-1 bg-w-fit-content text-center"
                  >
                    Interest
                  </label>
                  <input
                          type="text"
                          placeholder="Select"
                          name="interest"
                          className="input px-2 py-3 text-sm border-[1px] border-gray-300 rounded-md focus:outline-none bg-white p-[10px]"
                          value={selectedInterests.join(", ")}
                          onClick={() => {
                            setDropdownVisible(!DropdownVisible);
                          }}
                          autoComplete="off"
                        />
                        <div className="flex flex-wrap mt-2">
                          {selectedInterests.map((interest, index) => (
                            <div
                              key={index}
                              className="bg-[#FAC8CA] text-black rounded-full px-3 py-1 text-sm font-normal mr-2 mb-2 flex items-center"
                            >
                              {interest}
                              <button
                                type="button"
                                className="ml-1 text-red-800 font-bold   mx-1"
                                onClick={() => removeInterest(interest)}
                              >
                                X
                              </button>
                            </div>
                          ))}
                        </div>

                        {DropdownVisible && (
                          <ul className="absolute z-10 w-[19.5rem] mt-16 h-40 overflow-scroll bg-white border border-gray-300 rounded-md shadow-lg">
                            {interests.map((item, index) => (
                              <li
                                key={item._id}
                                className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                onClick={() => {
                                  handleInterestSelect(item.name);
                                  setDropdownVisible(false);
                                }}
                              >
                                {item.name}
                              </li>
                            ))}
                          </ul>
                        )}
                        {interesterror && <p className="text-red-500 font-extralight text-[12px]">{interesterror}</p>}
               </div>
               </div>
               
              {error && <p className="text-red-500">{error}</p>}
              {isLoading ? (
                <button
                  onClick={handleSubmit}
                  className="  bg-[#CB2229] text-white rounded-full font-all p-[6px] lg:mt-[0.5rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out relative mx-auto flex justify-center items-center"
                >
                  Updating profile <Spinner />
                </button>
              ) : (
                <button
                  onClick={handleSubmit}
                  className="  bg-[#CB2229] text-white rounded-full font-all p-[6px] lg:mt-[0.5rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out relative mx-auto flex justify-center"
                >
                  Update Profile
                </button>
              )}
            </form>
          </div>
        </section>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}

export default EditProfile;
