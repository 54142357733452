import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  AiOutlinePlusCircle,
  AiOutlineHeart,
  AiFillHeart,
} from "react-icons/ai";
import { GrLocation } from "react-icons/gr";
import { create_promotion } from "../../../../api/userRegestration/Campaign";
import { useWallet } from "../../../Wallet/Context/WalletContext";
export default function CreateCampaignForm() {
  const [headline, setHeadline] = useState("");
  const [description, setDescription] = useState("");
  const [keywords, setKeywords] = useState("");
  const [suggestedKeywords, setSuggestedKeywords] = useState("");
  const [location, setLocation] = useState("");
  const [sliderValue, setSliderValue] = useState(90);
  const [spinner, setspinner] = useState(false);
  const [error, setError] = useState("");
  const [suberror, setsubError] = useState("");
  const [campaginformData, setCampaginformData] = useState({
    headline: "",
    duration: "",
    bidAmount: "",
    photo: [],
    description: "",
    keyword: [],
    budget: "",
    startdate: "",
  });

  const { walletData } = useWallet();
  const [keywordInput, setKeywordInput] = useState("");
  const [keywordChips, setKeywordChips] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedImagePreview, setSelectedImagePreview] = useState(null);

  const handleDeleteKeyword = (indexToRemove) => {
    setKeywordChips((prevKeywords) => {
      return prevKeywords.filter((_, index) => index !== indexToRemove);
    });
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === "keyword") {
      setKeywordInput(value);
    } else {
      setCampaginformData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  const handleImageChange = (files) => {
    const imageFile = files[0];

    // Set the selected image preview URL
    setSelectedImagePreview(URL.createObjectURL(imageFile));

    // Store the selected image file in state
    setCampaginformData((prevData) => ({
      ...prevData,
      photo: imageFile,
    }));
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent form submission
      const trimmedKeyword = keywordInput.trim();
      if (trimmedKeyword && keywordChips.length < 5) {
        setError("");
        setKeywordChips((prevKeywords) => [...prevKeywords, trimmedKeyword]);
        setCampaginformData((prevData) => ({
          ...prevData,
          keyword: [...prevData.keyword, trimmedKeyword],
        }));
        setKeywordInput("");
      } else if (trimmedKeyword && keywordChips.length >= 5) {
        setError("You can only enter up to 5 keywords.");
      }
    }
  };

  const handlesubmit = async (e) => {
    e.preventDefault();

    // Calculate the duration in days
    const startDate = selectedDate ? new Date(selectedDate) : new Date();
    const endDate = new Date();
    const durationInMilliseconds = endDate - startDate;
    const durationInDays = Math.ceil(
      durationInMilliseconds / (1000 * 60 * 60 * 24)
    );

    // Set the duration in days to the form data
    setCampaginformData((prevData) => ({
      ...prevData,
      duration: `${durationInDays} day${durationInDays > 1 ? "s" : ""}`, // Pluralize 'day' if duration is greater than 1
    }));

    console.log(new Date().toLocaleTimeString());
    if (walletData.balance < campaginformData.budget) {
      setsubError("Please add balance to your wallet.");
      return;
    }

    if (campaginformData.bidAmount > campaginformData.budget) {
      setsubError("Bidding amount should be less than budget.");
      return;
    }

    setsubError("");

    setspinner(true);
    try {
      const formData = {
        headline: campaginformData.headline,
        duration: campaginformData.duration,
        bidAmount: campaginformData.bidAmount,
        description: campaginformData.description,
        photo: [campaginformData.photo],
        keyword: campaginformData.keyword,
        budget: campaginformData.budget,
        startDate: new Date().getTime(),
      };
      const campaignformdata = await create_promotion(formData);
      console.log(campaignformdata, "campaign=======");
    } catch (error) {
      console.log(error);
    }
  };

  const handleSliderChange = (e) => {
    setSliderValue(parseInt(e.target.value));
  };
  //
  return (
    <>
      <div className=" w-[93%]  mx-auto p-[5px] xs:p-0 xs:mb-20">
        <div className="border border-[#cfcaca82] rounded-md w-[68%] xs:w-[99%] mx-auto shadow-sm">
          <h1 className="text-center font-all xs:text-2xl font-semibold my-[12px] text-[19px]">
            Create a Campaign
          </h1>
          <form
            onSubmit={handlesubmit}
            className="flex flex-col justify-center items-center"
          >
            {/* item a  */}
            <div className="item-a xs:w-full">
              <div className="coolinput flex flex-col w-fit-content static lg:w-[32rem] ">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                >
                  Add headline
                </label>
                <input
                  type="text"
                  placeholder="Write here..."
                  name="headline"
                  required
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-none"
                  value={campaginformData.headline}
                  onChange={handleInputChange}
                />
              </div>
              {/* b  */}
              <div className="coolinput flex flex-col w-fit-content static  lg:w-[32rem] w-auto ">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                >
                  Add Description
                </label>
                <input
                  type="text"
                  placeholder="Write here..."
                  name="description"
                  value={campaginformData.description}
                  onChange={handleInputChange}
                  required
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-none"
                />
              </div>
              {/* c  text-area */}
              <div className="collinput-text-area--img  flex flex-col w-fit-content static max-w-240 lg:w-[32rem]">
                <input
                  type="file" // Use type "file" for image upload
                  id="imageInput"
                  accept="image/*" // Accept only image files
                  onChange={(e) => handleImageChange(e.target.files)} // Handle image change event
                  className="hidden" // Hide the input field visually
                />
                <label htmlFor="imageInput" className="cursor-pointer">
                  Click to Upload Image
                </label>
                {selectedImagePreview && (
                  <img
                    src={selectedImagePreview}
                    alt="Selected Image"
                    className="mt-2 max-h-40"
                  />
                )}
              </div>
            </div>
          </form>
        </div>
        <div className="item-b  mt-4 mb-2 xs:w-full">
          <div className="border border-[#cfcaca82]   rounded-md w-[68%] xs:w-[99%] mx-auto shadow-sm mb-3 ">
            <h1 className="font-all font-semibold text-[19px] text-start ml-6 mt-3 mb-2">
              Select Keywords
            </h1>
            {/* - a  */}
            <div className="coolinput flex mx-auto flex-col w-fit-content static max-w-240 lg:w-[32rem] mb-3 ">
              <label
                htmlFor="input"
                className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
              >
                Add Keywords
              </label>
              <input
                type="text"
                placeholder="Add Keywords"
                name="keyword"
                onKeyPress={handleKeyPress}
                value={keywordInput}
                onChange={handleInputChange}
                className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-none"
              />
            </div>
            {error && (
              <p className="text-red-500 flex justify-center">{error}</p>
            )}
            <div className="flex justify-center mx-16 mt-2 flex-wrap">
              {keywordChips.map((keyword, index) => (
                <div
                  key={index}
                  className="bg-[#cb22289b] flex rounded-full mx-1 px-3 py-1 my-3 text-sm font-normal text-white"
                  style={{ letterSpacing: "1px" }}
                >
                  {keyword}{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-3 h-3 my-auto ml-1 cursor-pointer"
                    onClick={() => {
                      handleDeleteKeyword(index);
                      setKeywordInput(""); // Clear input field when chip is deleted
                    }}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18 18 6M6 6l12 12"
                    />
                  </svg>
                </div>
              ))}
            </div>

            {/* - b  */}
            <div className="coolinput flex mx-auto flex-col w-fit-content static max-w-240 lg:w-[32rem] mb-3 ">
              <label
                htmlFor="input"
                className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
              >
                Suggested Keywords
              </label>
              <input
                type="text"
                placeholder="Write here..."
                name="input"
                value={suggestedKeywords}
                onChange={(e) => setSuggestedKeywords(e.target.value)}
                className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-none"
              />
            </div>
          </div>
        </div>
        <div className="item-c mt-4 mb-2 xs:w-full">
          <div className="border border-[#cfcaca82]   rounded-md w-[68%] xs:w-[99%] mx-auto shadow-sm mb-3 ">
            <h1 className="font-all font-semibold text-[19px] text-start ml-6 mt-3 mb-2">
              Advertise Near Location
            </h1>
            {/*  */}
            <div className="--get-location drop-c flex flex-col justify-center rounded-full mx-auto p-[9px] w-[14rem]">
              <span className="flex flex-row items-center justify-center gap-2">
                <GrLocation /> Use Current Location
              </span>
            </div>
            <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[32rem] mx-auto ">
              <label
                htmlFor="input"
                className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
              >
                Location
              </label>
              <input
                type="text"
                placeholder="Write here..."
                name="input"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-none"
              />
            </div>
            <div className="w-3/4 mx-auto mt-4 mb-4">
              <label
                htmlFor="range"
                className=" font-all  text-lg font-semibold"
              >
                Selected Distance ( {sliderValue} km):
              </label>
              <input
                type="range"
                id="range"
                name="range"
                min="20"
                max="500"
                value={sliderValue}
                onChange={handleInputChange}
                className="w-full mt-2 bg-yellow-200"
              />
              <div className="flex justify-between mt-2">
                <span>20 km</span>
                <span>{sliderValue} km</span>
              </div>
            </div>

            {/*  */}
          </div>
          <div className="item-d setbudget border border-[#cfcaca82]  xs:w-full  rounded-md w-[68%] mx-auto shadow-sm mb-3 mt-4">
            <h1 className=" font-all font-semibold text-[19px] text-start ml-6 mt-3 mb-2">
              Add Budget
            </h1>
            {/* a  */}
            <div className="flex flex-col justify-center items-center">
              <div className="coolinput flex flex-col w-fit-content static xs:w-full xs:p-2 lg:w-[32rem] ">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                >
                  Enter your Budget
                </label>
                <input
                  type="number"
                  placeholder="Write here..."
                  value={campaginformData.budget}
                  onChange={handleInputChange}
                  name="budget"
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-none"
                />
              </div>
              <div className="coolinput flex flex-col w-fit-content static xs:w-full xs:p-2 lg:w-[32rem] ">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                >
                  Max Bidding amount
                </label>
                <input
                  type="number"
                  placeholder="Write here..."
                  name="bidAmount"
                  value={campaginformData.bidAmount}
                  onChange={handleInputChange}
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-none"
                />
              </div>
              {/* b  */}
              <div className="flex flex-row  xs:gap-2 items-center justify-start gap-[10rem] ">
                <h5 className="font-base text-[19px]">
                  <h1>Current Balance</h1>
                  {walletData.balance.toLocaleString("en-IN")}
                  <span className="font-all text-sm"> inr</span>{" "}
                </h5>
                <button className=" bg-[#cac5c585] shadow-md lg:mx-auto xs:m-2 p-[6px] xs:w-[8rem] w-[13rem] rounded-full transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white">
                  add money
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="--item-d --run add-money xs:w-full">
          <div className="border border-[#cfcaca82]   rounded-md xs:w-full w-[68%] mx-auto shadow-sm mb-3 mt-4">
            <h1 className="font-all font-semibold text-[19px] text-start ml-6 mt-3 mb-2">
              How long will u take to run this ad
            </h1>
            <div className="flex flex-col items-center justify-center gap-[9px] xs:w-[90%]  ">
              <div className="flex flex-row items-center gap-2 xs:w-[90%]">
                <div className="coolinput flex flex-col w-fit-content static xs:w-[99%] max-w-240 lg:w-[32rem]  ">
                  <DatePicker
                    selected={selectedDate}
                    onChange={handleDateChange}
                    minDate={new Date()}
                    placeholderText="Select a date"
                    className="input px-2 py-3 text-sm border-[1px] xs:w-full border-[#738482] rounded-md focus:border-none"
                  />
                </div>
              </div>

              <hr className="w-full" />
            </div>
          </div>
        </div>

        <div className="--item-c--input-form-velow-section xs:w-[99%]">
          <div className="border border-[#cfcaca82]   rounded-md w-[68%] xs:w-[99%] mx-auto shadow-sm mb-3 mt-4">
            <div className="flex flex-col justify-center items-center">
              <div className="coolinput flex flex-col w-fit-content static xs:w-[99%] max-w-240 lg:w-[32rem] ">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                >
                  VPA Address
                </label>
                <input
                  type="text"
                  placeholder="Write here..."
                  name="input"
                  // value={vpaAddress}
                  // onChange={(e) => setVpaAddress(e.target.value)}
                  className="input px-2 py-3 xs:w-[99%] text-sm border-[1px] border-[#738482] rounded-md focus:border-none"
                />
              </div>
              {/*  */}
              <div className="coolinput flex flex-col w-fit-content static xs:w-[99%] max-w-240 lg:w-[32rem] ">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                >
                  Billing Address
                </label>
                <input
                  type="text"
                  placeholder="Write here..."
                  name="input"
                  className="input px-2 py-3 xs:w-[99%] text-sm border-[1px] border-[#738482] rounded-md focus:border-none"
                />
              </div>
              {/*  */}
              <div className="coolinput flex flex-col w-fit-content static xs:w-[99%] max-w-240 lg:w-[32rem] ">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                >
                  Country
                </label>
                <input
                  type="text"
                  placeholder="Write here..."
                  name="input"
                  // value={country}
                  // onChange={(e) => setCountry(e.target.value)}
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-none"
                />
              </div>
              {/*  */}
              <div className="coolinput flex flex-col w-fit-content static xs:w-[99%] max-w-240 lg:w-[32rem] ">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                >
                  Street Address
                </label>
                <input
                  type="text"
                  placeholder="Write here..."
                  name="input"
                  // value={streetAddress}
                  // onChange={(e) => setStreetAddress(e.target.value)}
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-none"
                />
              </div>
              {/*  */}
              <div className="coolinput flex flex-col w-fit-content static xs:w-[99%] max-w-240 lg:w-[32rem] ">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                >
                  City
                </label>
                <input
                  type="text"
                  placeholder="Write here..."
                  name="input"
                  // value={city}
                  // onChange={(e) => setCity(e.target.value)}
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-none"
                />
              </div>
              {/*  */}
              <div className="coolinput flex flex-col w-fit-content static xs:w-[99%] max-w-240 lg:w-[32rem] ">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                >
                  state / province
                </label>
                <input
                  type="text"
                  placeholder="Write here..."
                  name="input"
                  // value={stateProvince}
                  // onChange={(e) => setStateProvince(e.target.value)}
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-none"
                />
              </div>
              {/*  */}
              <div className="coolinput flex flex-col w-fit-content static xs:w-[99%] max-w-240 lg:w-[32rem] ">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                >
                  Postal Code
                </label>
                <input
                  type="number"
                  placeholder="Write here..."
                  name="input"
                  // value={postalCode}
                  // onChange={(e) => setPostalCode(e.target.value)}
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-none"
                />
              </div>
              {/*  */}
              <div className="coolinput flex flex-col w-fit-content static xs:w-[99%] max-w-240 lg:w-[32rem] ">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                >
                  Tax Status
                </label>
                <input
                  type="text"
                  placeholder="Write here..."
                  name="input"
                  // value={taxStatus}
                  // onChange={(e) => setTaxStatus(e.target.value)}
                  className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-none"
                />
              </div>
              {/* <div className="collinpu-text-area--img  flex flex-col w-fit-content static xs:w-[99%] max-w-240 lg:w-[32rem]">
                <label
                  htmlFor="input"
                  className=" text text-sm  text-black w-max rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                >
                  Billing Address & Text Information
                </label>
                <div className="mb-4  h-[9rem] input px-2 py-3 text-sm border-[1px] border-[#738482] border-dashed rounded-md focus:border-none">
                  <span className="p-[40px] text-[42px] flex justify-center items-center text-[#D2D2D2]">
                    <AiOutlinePlusCircle />
                  </span>
                </div>
              </div> */}
            </div>
          </div>
        </div>

        <button
          onClick={handlesubmit}
          className=" bg-[#CB2229] text-white rounded-full font-all p-[6px] lg:mt-[1rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out mx-auto flex justify-center"
        >
          Confirm and Make Payment
        </button>
        {suberror && (
          <p className="text-red-500 flex justify-center">{suberror}</p>
        )}
      </div>
    </>
  );
}
