import React, { useContext, useState, useEffect } from "react";
import { Breadcrumb } from "flowbite-react";
import { ChatContext } from "../../../../../Context/ChatContext";
import { useParams } from "react-router-dom";
import { HiHome } from "react-icons/hi";
import Loader from "../../../../../component/Loader";
import {
  update_campaign_by_id,
  view_promotion_byid,
} from "../../../../../api/userRegestration/Campaign";
function FeaturedProduct() {
  const [loading, setloading] = useState(true);
  const { receiverId, setReceiverId } = useContext(ChatContext);

  const { campaignId } = useParams();
  const { promotionId, type } = useParams();

  const [spinner, setspinner] = useState(false);
  const [productdetails, setproductdetails] = useState({});
  const [error, setError] = useState(null);
  const types = new URLSearchParams(window.location.search).get("type");
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    duration: "",
    bidAmount: "",
    image: null,
    description: "",
    headline: "",
  });
  const handleEditClick = () => {
    setIsEditing(true);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleImageChange = (e) => {
    setFormData({
      ...formData,
      image: e.target.files[0],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setspinner(true);
    try {
      // Construct form data
      const formDataToSend = new FormData();
      formDataToSend.append("duration", formData.duration);
      formDataToSend.append("bidAmount", formData.bidAmount);
      formDataToSend.append("image", formData.photo);
      formDataToSend.append("description", formData.description);
      formDataToSend.append("description", formData.headline);

      const response = await update_campaign_by_id(campaignId, formData);
      const data = await response.json();
      console.log("API Response:", data);
      setFormData({
        duration: "[]",
        bidAmount: "[]",
        image: null,
        description: "[]",
        headline: "",
      });
      // setIsEditing(false);
    } catch (error) {
      console.error("Error:", error);
      setError(error);
    }
  };

  useEffect(() => {
    view_promotion_byid(promotionId)
      .then((response) => {
        console.log("API Response:", response);
        setproductdetails(response);
      })
      .catch((error) => {
        console.error("Error:", error);
        setError(error);
      });
  }, [promotionId]);

  return (
    <>
      <div className="bg-layout shadow-md drop-shadow-md lg:w-[155vh] mb-[7rem] lg:ml-[268px] relative top-[1rem] xs:h-full">
        {productdetails && (
          <>
            <div className="--action breadcrumb--navigate-path ml-8 mt-8">
              <Breadcrumb aria-label="Default breadcrumb example">
                <Breadcrumb.Item icon={HiHome}>
                  <p className="cursor-pointer ">Home</p>
                </Breadcrumb.Item>

                <Breadcrumb.Item className="cursor-pointer">
                  Product
                </Breadcrumb.Item>

                <Breadcrumb.Item>{productdetails.type}</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <section className="/">
              <div className="lg:container mx-auto px-4">
                <div className="lg:col-gap-12 xs:flex-col xl:col-gap-16 mt-8 grid grid-cols-1 gap-12 lg:mt-12 lg:grid-cols-5 lg:gap-16">
                  {isEditing ? (
                    <div className="lg:col-span-5 lg:row-end-1">
                      {/* Render Edit Form Component */}
                      <div className="border border-[#cfcaca82] rounded-md w-[68%] xs:w-[99%] mx-auto shadow-sm">
                        <h1 className="text-center font-all xs:text-2xl font-semibold my-[12px] text-[19px]">
                          Update Product
                        </h1>
                        <form
                          onSubmit={handleSubmit}
                          className="flex flex-col justify-center pb-3 mb-6 items-center"
                        >
                          {/* Form Fields */}
                          <div className="item-a xs:w-full">
                            {/* Duration */}
                            <div className="coolinput flex flex-col w-fit-content static lg:w-[32rem] ">
                              <label
                                htmlFor="duration"
                                className="text text-sm text-black w-max rounded-md bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                              >
                                Enter Duration
                              </label>
                              <input
                                type="text"
                                placeholder="Write here..."
                                required
                                name="duration"
                                className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
                                value={formData.duration}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="coolinput flex flex-col w-fit-content static lg:w-[32rem] ">
                              <label
                                htmlFor="headline"
                                className="text text-sm text-black w-max rounded-md bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                              >
                                Enter Headline
                              </label>
                              <input
                                type="text"
                                placeholder="Write here..."
                                required
                                name="headline"
                                className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
                                value={formData.headline}
                                onChange={handleChange}
                              />
                            </div>
                            {/* b  */}
                            <div className="coolinput flex flex-col w-fit-content static lg:w-[32rem] ">
                              <label
                                htmlFor="bidAmount"
                                className="text text-sm text-black w-max rounded-md bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                              >
                                Enter Bid Amount
                              </label>
                              <input
                                type="text"
                                placeholder="Write here..."
                                required
                                name="bidAmount"
                                className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
                                value={formData.bidAmount}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="coolinput flex flex-col w-fit-content static lg:w-[32rem]">
                              <label
                                htmlFor="image"
                                className="text text-sm text-black w-max rounded-md bg-[white] mb-3 relative top-2.5 ml-2 px-1 bg- w-fit-content"
                              >
                                Add Image
                              </label>
                              <input
                                type="file"
                                id="image"
                                accept="image/*"
                                required
                                onChange={handleImageChange}
                              />
                              <label htmlFor="image" className="cursor-pointer">
                                <img
                                  src={
                                    formData.photo
                                      ? URL.createObjectURL(formData.photo)
                                      : ""
                                  }
                                  className="mx-auto image-coin"
                                  alt=""
                                />
                              </label>
                            </div>
                            <div className="coolinput flex flex-col w-fit-content static lg:w-[32rem] ">
                              <label
                                htmlFor="description"
                                required
                                className="text text-sm text-black w-max rounded-md bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
                              >
                                Enter Description
                              </label>
                              <input
                                type="text"
                                placeholder="Write here..."
                                name="description"
                                className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
                                value={formData.description}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <button
                            type="submit"
                            className="mb-6 border-[1px] border-[#CB2229] text-black rounded-full font-all p-[6px] lg:mt-[0.5rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out relative"
                          >
                            Update
                          </button>
                        </form>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="lg:col-span-3 lg:row-end-1">
                        <div className="lg:flex lg:items-start">
                          <div className="lg:order-2 lg:ml-5">
                            <div className="max-w-xl overflow-hidden rounded-lg ">
                              <img
                                className="h-[17rem] w-full mx-auto max-w-full object-cover"
                                src={productdetails.photo}
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="lg:col-span-2 lg:row-span-2 lg:row-end-2">
                        <h1 className="sm: text-2xl font-bold text-gray-900 sm:text-3xl  xs:text-4xl font-all">
                          {productdetails.type} <span> </span>
                        </h1>
                        <div className="mt-5 flex items-center">
                          <div className="flex items-center">
                            <svg
                              className="block h-4 w-4 align-middle text-yellow-500"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                                className=""
                              />
                            </svg>
                            <svg
                              className="block h-4 w-4 align-middle text-yellow-500"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                                className=""
                              />
                            </svg>
                            <svg
                              className="block h-4 w-4 align-middle text-yellow-500"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                                className=""
                              />
                            </svg>
                            <svg
                              className="block h-4 w-4 align-middle text-yellow-500"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                                className=""
                              />
                            </svg>
                            <svg
                              className="block h-4 w-4 align-middle text-yellow-500"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                                className=""
                              />
                            </svg>
                          </div>
                          <p className="ml-2 text-sm font-medium text-gray-500">
                            1,209 Reviews
                          </p>
                        </div>
                        <h2 className="mt-8 text-base text-gray-900 xs:text-2xl">
                          Product Category {productdetails.type}
                        </h2>

                        {/*  */}
                        {/* Product rate  */}
                        <h2 className="mt-8 text-base text-gray-900 font-bold font-all xs:text-2xl">
                          Product rate :{" "}
                          <span className="italic font-normal">
                            {" "}
                            {productdetails.bidAmount}{" "}
                          </span>
                        </h2>

                        <h2 className="mt-3 text-base text-gray-900 font-bold font-all xs:text-2xl">
                          Product Details : <br />{" "}
                          <span className="italic font-normal ">
                            {" "}
                            "{productdetails.description}"
                          </span>
                        </h2>
                        {types === "active_campaign" && (
                          <button
                            onClick={handleEditClick}
                            className="mb-6 border-[1px] border-[#CB2229] text-black rounded-full font-all p-[6px] lg:mt-[0.5rem] w-[18rem] transform hover:scale-105 duration-500 ease-in-out relative"
                          >
                            Edit
                          </button>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </section>
          </>
        )}
      </div>
    </>
  );
}

export default FeaturedProduct;
