import React, { useContext, useEffect, useState } from "react";
import { Breadcrumb } from "flowbite-react";
import { HiHome } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { fetchServiceDetails } from "../../../../../api/userRegestration/CollectionForm";
import { ChatContext } from "../../../../../Context/ChatContext";
import UserChatButton from "../../../../../Widgets/UserChatButton";
import Loader from "../../../../../component/Loader";
//
export default function ServiceListDetails() {
  //
  //
  const { serviceId } = useParams();
  const navigate = useNavigate();
  const [productdetails, setproductdetails] = useState();
  const { receiverId, setReceiverId } = useContext(ChatContext);
  const [loading, setloading] = useState(true);

  const Home = () => {
    navigate(`/`);
  };
  //
  useEffect(() => {
    fetchServiceDetails(serviceId)
      .then((response) => {
        console.log("API Response:", response);
        setproductdetails(response.result);
        setloading(false);
        setReceiverId(response.result.creatorId);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [serviceId]);
  return (
    <>
      <>
        <div className="bg-layout shadow-md drop-shadow-md  mb-[7rem] xs:w-auto xs:ml-auto  ml-[17%] w-[81%] relative top-[1rem]">
          {loading ? (
            <div className="loader">
              <Loader />
            </div>
          ) : (
            productdetails && (
              <>
                <div className="--action breadcrumb--navigate-path ml-8 mt-8">
                  <Breadcrumb aria-label="Default breadcrumb example">
                    <Breadcrumb.Item icon={HiHome}>
                      <p className="cursor-pointer " onClick={Home}>
                        Home
                      </p>
                    </Breadcrumb.Item>

                    <Breadcrumb.Item
                      className="cursor-pointer"
                      //   onClick={handleClick}
                    >
                      Product
                    </Breadcrumb.Item>

                    <Breadcrumb.Item>
                      {productdetails.serviceName}
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </div>
                <section className="/">
                  <div className="container mx-auto">
                    <div className="lg:col-gap-12 xl:col-gap-16 mt-5 grid grid-cols-1 gap-8 lg:mt-12 lg:grid-cols-5 lg:gap-16">
                      <div className="lg:col-span-3 lg:row-end-1">
                        <div className="lg:flex lg:items-start">
                          <div className="lg:order-2 lg:ml-5">
                            <div className="max-w-xl overflow-hidden rounded-lg">
                              <img
                                className="h-full w-full max-w-full object-cover"
                                src={productdetails.mediaUrl}
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="lg:col-span-2 lg:row-span-2 lg:row-end-2">
                        <h1 className="sm: text-2xl font-bold text-gray-900 sm:text-3xl font-all">
                          {productdetails.serviceName} <span> </span>
                        </h1>
                        <h2 className="mt-4 text-base text-gray-900">
                          Service Categories
                        </h2>
                        <div className="mt-3 flex select-none flex-wrap items-center gap-1">
                          <label>
                            <p className=" bg-gray-200 px-6 py-2 font-bold">
                              {productdetails.categorie}
                            </p>
                          </label>
                        </div>
                        {/*  */}
                        {/* Product rate  */}`
                        <h2 className="mt-4 text-base text-gray-900 font-bold font-all">
                          Service rate :{" "}
                          <span className="italic font-normal">
                            {" "}
                            {productdetails.rate}{" "}
                          </span>
                        </h2>
                        {/* minimum order quanity  */}
                        {/* <h2 className="mt-3 text-base text-gray-900 font-bold font-all">
           Minimum Order Quanity (MOD) :{" "}
           <span className="italic font-normal"> {productdetails.moq} </span>
         </h2> */}
                        {/* <h2 className="mt-3 text-base text-gray-900 font-bold font-all">
           MQ unit :{" "}
           <span className="italic font-light"> {productdetails.mqu} </span>{" "}
         </h2> */}
                        <h2 className="mt-3 text-base text-gray-900 font-bold font-all">
                          Service Details :
                          <span className="italic font-normal">
                            {" "}
                            " {productdetails.details} "
                          </span>
                        </h2>
                        <div className="flex flex-col gap-[0.5rem]  mt-[1.1rem]">
                          <button className=" bg-[#F8F8F8]   border-[1px] border-[#CB2229] shadow-md lg:mx-auto p-[6px] w-[22rem] rounded-full transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white">
                            leave a meassage
                          </button>
                          <UserChatButton receiverId={receiverId} />
                        </div>
                      </div>
                    </div>
                  </div>
                 

                  <div className="">
                    {/* <div className="c-1 flex flex-row gap-[13px] justify-center">
     <span className="bg-[#E6E6E6] flex flex-row  gap-[6px] justify-center items-center  rounded-md p-[17px] w-[7rem] cursor-pointer transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white">
       {" "}
       <AiOutlineEye /> views
     </span>
     <span className="bg-[#E6E6E6] flex flex-row gap-[6px]  justify-center items-center  rounded-md p-[17px] w-[7rem] cursor-pointer transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white">
       {" "}
       <MdAdsClick /> clicks
     </span>
     <span className="bg-[#E6E6E6] flex flex-row gap-[6px]  justify-center items-center  rounded-md p-[17px] w-[7rem] cursor-pointer transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white">
       {" "}
       <AiOutlineHeart /> Likes
     </span>

     <span className="bg-[#E6E6E6] flex flex-row gap-[6px]  justify-center items-center  rounded-md p-[17px] w-[7rem] cursor-pointer transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white">
       {" "}
       <AiOutlineQuestionCircle /> Inquiries
     </span>
     <span className="bg-[#E6E6E6] flex flex-row gap-[6px]  justify-center items-center  rounded-md p-[17px] w-[7rem] cursor-pointer transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white">
       {" "}
       <BsGraphUpArrow /> Ranking
     </span>
     <span className="bg-[#E6E6E6] flex flex-row gap-[6px]  justify-center items-center  rounded-md p-[17px] w-[7rem] cursor-pointer transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white">
       {" "}
       <AiOutlineShareAlt /> share
     </span>
     
   </div> */}
                  </div>
                 

                  {/* <div className="--other-product-sslider">
     <ProductBuyPAgeSlider />
   </div> */}
                </section>
              </>
            )
          )}
        </div>
      </>
    </>
  );
}
