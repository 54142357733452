import axios from "axios";
import * as ENDPOINTS from "../URlcollection";
export const create_promotion = async (formData) => {
  try {
    const response = await axios.post(
      "https://inobackend-production.up.railway.app/api/v1/user/createCampaignPromotion",
      formData,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );

    if (response.status === 200) {
      const productdata = response.data;
      console.log(" resposnse post:", productdata);
      return productdata;
    } else {
      throw new Error("Failed to  user");
    }
  } catch (error) {
    console.error("Error  :", error.message);
    throw new Error("Failed to productdata ");
  }
};
// 
export const userallcampaign = async (accessToken) => {
  try {
    const response = await axios.get(
      `https://inobackend-production.up.railway.app/api/v1/user/getUserAllCampaigns`,
      {
        headers: {
          Token: localStorage.getItem("token"),
        },
      }
    );

    if (response.status === 200) {
      return response.data.result;
    } else {
      throw new Error("Failed to fetch product categories");
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
export const activecampaignuser = async (accessToken) => {
  try {
    const response = await axios.get(
      `https://inobackend-production.up.railway.app/api/v1/user/getUserActiveCampaigns`,
      {
        headers: {
          Token: localStorage.getItem("token"),
        },
      }
    );

    if (response.status === 200) {
      return response.data.result;
    } else {
      throw new Error("Failed to fetch product categories");
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
export const all_campaign_list = async (accessToken) => {
  try {
    const response = await axios.get(
      `https://inobackend-production.up.railway.app/api/v1/user/getCampaignsList`,
      {
        headers: {
          Token: localStorage.getItem("token"),
        },
      }
    );

    if (response.status === 200) {
      return response.data.result;
    } else {
      throw new Error("Failed to fetch product categories");
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const click_on_promotion = async (promotionId) => {
  try {
    const response = await axios.post(
      `https://inobackend-production.up.railway.app/api/v1/user/clickOnPromotion`,
      { promotionId: promotionId }, 
      {
        headers: {
          Token: localStorage.getItem("token"),
        },
      }
    );
    if (response.status === 200) {
      return response.data.result;
    } else {
      // Handle other status codes if needed
    }
  } catch (error) {
    console.error("Error fetching promotions", error);
    throw error;
  }
};



export const view_promotion_byid = async (promotionId) => {
  try {
    const response = await axios.get(
      `https://inobackend-production.up.railway.app/api/v1/user/viewPromotionById?promotionId=${promotionId}`,
      // { promotionId: promotionId }, 
      {
        headers: {
          Token: localStorage.getItem("token"),
        },
      }
    );
    if (response.status === 200) {
      return response.data.result;
    } else {
      // Handle other status codes if needed
    }
  } catch (error) {
    console.error("Error fetching promotions", error);
    throw error;
  }
};

export const update_campaign_by_id= async (campaignId,formData,e) => {

  try {
    const response = await axios.post(
      `https://inobackend-production.up.railway.app/api/v1/user/updateUserCampaignById`,
      campaignId,formData, 
      {
        headers: {
          Token: localStorage.getItem("token"),
        },
      }
    );
    if (response.status === 200) {
      return response.data.result;
    } else {
      // Handle other status codes if needed
    }
  } catch (error) {
    console.error("Error fetching promotions", error);
    throw error;
  }
};


export const view_product_by_id = async (postId) => {
  try {
    const response = await axios.get(
      `https://inobackend-production.up.railway.app/api/v1/user/postView?postId=${postId}`,
      // { postId: postId }, 
      {
        headers: {
          Token: localStorage.getItem("token"),
        },
      }
    );
    if (response.status === 200) {
      return response.data.result;
    } else {
      // Handle other status codes if needed
    }
  } catch (error) {
    console.error("Error fetching promotions", error);
    throw error;
  }
};
